import React, { useEffect } from 'react';
import {
  Box, Button, Collapse, IconButton, Grid, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TableSortLabel, TableFooter, TablePagination
} from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp, Security } from "@mui/icons-material";
import { CustomerReleaseType, ReleaseType } from "../../DownloadReleases";
import GenericButton from "../../../widgets/buttons/generic/GenericButton";
import { ReleaseFileType } from "../../../releases/types";
import { faLinux, faWindows } from "@fortawesome/free-brands-svg-icons";
import { faCopy, faFile } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import PrimaryButton from "../../../widgets/buttons/primary/PrimaryButton";
import BarLoader from '../../../widgets/loaders/barLoader/BarLoader';
import styles from "./CollapseReleasesTable.module.css"
import TablePaginationActions from "../../../layout/tablePagination/TablePaginationActions";

type CollapsibleTableProps = {
  customerReleases: CustomerReleaseType[],
  copyToClipboard?: (text: string) => void,
  openLicense?: (event: React.MouseEvent<HTMLButtonElement>, customerRelease: CustomerReleaseType, releaseFileId: number) => void,
  downloadFile?: (releaseId: number, releaseFileId: number) => void,
  loading: boolean
}

type PopoverStateType = {
  [key: string]: {
    anchor: HTMLButtonElement | null,
    open: boolean
  }
}

type Order = 'asc' | 'desc';

const defineIcon = (description: string) => {
  if (description.toLowerCase().indexOf('linux') >= 0) {
    return faLinux;
  } else if (description.toLowerCase().indexOf('windows') >= 0) {
    return faWindows;
  } else {
    return faFile;
  }
};

const adjustReleaseDate = (releaseDate: string) => {
  return moment(releaseDate).format("MMMM Do, YYYY");
};

const releaseNotesUrl = 'https://success.planview.com/Planview_Hub/Planview_Hub_Product_Releases';

const hubUserGuideUrl = 'https://success.planview.com/Planview_Hub';

function ReleaseRow(props: {
  customerRelease: CustomerReleaseType,
  openLicense: (event: React.MouseEvent<HTMLButtonElement>, customerRelease: CustomerReleaseType, releaseFileId: number) => void,
  copyToClipboard: (text: string) => void,
  downloadFile: (releaseId: number, releaseFileId: number) => void
}) {
  const { customerRelease, openLicense, copyToClipboard, downloadFile } = props;
  const [open, setOpen] = React.useState(false);
  const licenceAccepted = customerRelease.licenseAcceptanceDate !== null && new Date() >= new Date(customerRelease.licenseAcceptanceDate);

  const defaultPopoverState = customerRelease.release.releaseFiles.reduce<PopoverStateType>((accumulator, currentValue) => {
    accumulator[currentValue.description] = {
      anchor: null,
      open: false
    };
    return accumulator;
  }, {});
  const [popoverState, setPopoverState] = React.useState<PopoverStateType>(defaultPopoverState);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, key: string) => {
    setPopoverState({
      ...popoverState,
      [key]: {
        anchor: event.currentTarget,
        open: true
      }
    });
  };

  const handleClose = (key: string) => {
    setPopoverState({
      ...popoverState,
      [key]: {
        anchor: null,
        open: false
      }
    });
  };

  return (
    <React.Fragment>
      <TableRow className={styles.table_row}>
        <TableCell component="th" scope="row">
          <Grid container >
            <Grid item xs={8}>{customerRelease.release.name}</Grid>
            <Grid item xs={4}>
              {customerRelease.release.security ? <Grid item>
                <Security />
              </Grid> : null}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="center">{customerRelease.release.productId.name}</TableCell>
        <TableCell align="center">{adjustReleaseDate(customerRelease.release.releaseDate)}</TableCell>
        <TableCell align="center">
          {customerRelease.release.releaseFiles ? customerRelease.release.releaseFiles.map((releaseFile: ReleaseFileType) => {
            return <GenericButton
              key={releaseFile.id}
              icon={defineIcon(releaseFile.description)}
              title={customerRelease.release.productId.name + ' ' + customerRelease.release.name + ' ' + releaseFile.description}
              onClick={licenceAccepted ? () => {
                downloadFile(customerRelease.release.id, parseInt(releaseFile.id))
              } : (event) => {
                openLicense(event, customerRelease, parseInt(releaseFile.id));
              }}
            />;
          }) : null}
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={styles.table_style} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit={true}>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {props.customerRelease.release.description}
              </Typography>
              <Table size="small" aria-label="expanded table" role="table">
                <TableBody>
                  <TableRow>
                    <TableCell width="50%" padding="normal">
                      <PrimaryButton onClick={() => { window.open(releaseNotesUrl, "_blank", 'noopener,noreferrer') }}
                        label="Release Notes" />
                      { customerRelease.release.productId.name === 'Tasktop Integration Hub' &&
                        <PrimaryButton onClick={() => { window.open(hubUserGuideUrl, "_blank", 'noopener,noreferrer') }}
                          label="User Guide" /> }
                    </TableCell>
                    <TableCell align="left">
                      {customerRelease.release.releaseFiles.map((releaseFile) => {
                        return <div key={releaseFile.id}>
                          <Button
                            title={releaseFile.description}
                            className={styles.checksumButton}
                            variant="outlined"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClick(event, releaseFile.description)}
                            size="small"
                          >
                            {releaseFile.description} SHA256
                          </Button>
                          <Popover
                            open={popoverState[releaseFile.description].open}
                            anchorEl={popoverState[releaseFile.description].anchor}
                            onClose={() => handleClose(releaseFile.description)}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            <div className={styles.copy_checksum}>
                              <Typography>{releaseFile.checksum}</Typography>
                              <GenericButton icon={faCopy} title='copyChecksum' onClick={() => copyToClipboard(releaseFile.checksum)} />
                            </div>
                          </Popover>
                        </div>
                      })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const CollapsibleReleasesTable: React.FC<CollapsibleTableProps> = (
  { customerReleases, copyToClipboard, loading, openLicense, downloadFile }: CollapsibleTableProps) => {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  type OrderByType = 'product' | 'name' | 'releaseDate';

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<OrderByType>('name');

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  function getComparator(order: Order, orderBy: OrderByType): (a: CustomerReleaseType, b: CustomerReleaseType) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a.release, b.release, orderBy)
      : (a, b) => -descendingComparator(a.release, b.release, orderBy);
  }

  const semanticVersionSort = (a: ReleaseType, b: ReleaseType) => {
    const comparableA = a['name'].split('.');
    const comparableB = b['name'].split('.');

    const len = Math.min(comparableA.length, comparableB.length);

    for (let i = 0; i < len; ++i) {
      const a1 = +comparableA[ i ] || 0;
      const b1 = +comparableB[ i ] || 0;

      if (a1 !== b1) {
        return b1 < a1 ? -1 : 1;
      }
    }
    return comparableB.length - comparableA.length
  }

  const descendingComparator = (a: ReleaseType, b: ReleaseType, orderBy: OrderByType) => {
    let comparableA, comparableB;
    if (orderBy === 'product') {
      comparableA = a['productId']['name'];
      comparableB = b['productId']['name'];
    } else if (orderBy === 'name') {
      return semanticVersionSort(a,b)
    } else {
      comparableA = a[orderBy];
      comparableB = b[orderBy];
    }
    if (comparableB < comparableA) {
      return -1;
    }
    if (comparableB > comparableA) {
      return 1;
    }
    return 0;
  };

  const releaseDownloadSort = (array: CustomerReleaseType[], comparator: (a: CustomerReleaseType, b: CustomerReleaseType) => number) => {
    const releaseDownloads = array.map((el, index) => [el, index] as [CustomerReleaseType, number]);
    releaseDownloads.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return releaseDownloads.map((el) => el[0]);
  };

  const sortReleaseDownloads = (sortBy: OrderByType) => () => {
    const isAsc = orderBy === sortBy && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(sortBy);
  }


  const rows = releaseDownloadSort(customerReleases.map((customerReleases) => {
    return customerReleases
  }), getComparator(order, orderBy));

  useEffect(() => {
    setPage(0)
  }, [customerReleases])

  if (loading) return (<BarLoader />);

  const tableContents = rows.map((row) =>
    <ReleaseRow
      key={row.id}
      customerRelease={row}
      openLicense={openLicense!}
      copyToClipboard={copyToClipboard!}
      downloadFile={downloadFile!}
    />
  );

  return (
    <TableContainer>
      <Table aria-label="collapsible table" role="table" className={styles.table}>
        <TableHead className={styles.table_head}>
          <TableRow>
            <TableCell sortDirection={orderBy === 'name' ? order : false}>
              <TableSortLabel
                active={orderBy === 'name'}
                direction={orderBy === 'name' ? order : 'asc'}
                onClick={sortReleaseDownloads('name')}>
                Version
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === 'product' ? order : false} align="center">
              <TableSortLabel
                active={orderBy === 'product'}
                direction={orderBy === 'product' ? order : 'asc'}
                onClick={sortReleaseDownloads('product')}>
                Product
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === 'releaseDate' ? order : false} align="center">
              <TableSortLabel
                active={orderBy === 'releaseDate'}
                direction={orderBy === 'releaseDate' ? order : 'asc'}
                onClick={sortReleaseDownloads('releaseDate')}>
                Release Date
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">Downloads</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody className={styles.table_body}>
          {(rowsPerPage > 0
            ? tableContents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : tableContents
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              count={tableContents.length}
              rowsPerPage={rowsPerPage}
              page={page}
              ActionsComponent={TablePaginationActions}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default CollapsibleReleasesTable;

